<template>
  <div>
    <validation-observer ref="VForm">
      <b-form @submit.prevent="doSubmit">
        
        <div class="row mt-2">
          <div class="col-md-12">
            <table class="table table-striped table-sm table-hover table-bordered">
                <thead>
                <tr>
                    <th width="48">Aksi</th>
                    <th width="160">Jenis</th>
                    <th width="320">Nama</th>
                    <th width="150">Jumlah</th>
                    <th width="210">Frekuensi</th>
                    <th>Keterangan</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(v,k) in resepDokter" :key="'resepDokter'+k">
                    <td>
                    <a href="javascript:;" @click="removeObat(v,k)"
                        class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round"><i
                        class="icon-trash"></i></a>
                    </td>
                    <td>
                    <v-select placeholder="Pilih Item" v-model="resepDokter[k].aurd_jenis"
                        :options="Config.mr.configResepAlkes" label="text"
                        @input="changeJenis($event,k)" :reduce="v=>v.value">
                    </v-select>
                    <VValidate :name="'Jenis '+(k+1)" message="Jenis Harus Diisi"
                        v-model="resepDokter[k].aurd_jenis" :rules="{required : 1}" />
                    </td>
                    <td>
                    <div class="d-flex">
                        <template v-if="resepDokter[k]['aurd_jenis'] == 1">
                        <v-select style="flex: 1;" class="med-selection select-paging"
                            placeholder="Pilih Item"
                            :class="obatLen <= 5 ? 'sp-col diagnosa-section': 'sp-col'"
                            @open="countLen" @search="filterObat($event,k);countLen();"
                            v-model="resepDokter[k].aurd_item_id" :options="mObatPreferensi"
                            label="text" @input="selectJumlahSatuan($event,k)" :clearable="true"
                            :reduce="v=>v.value"
                            :selectable="v=>v.value !== 'pd' && v.value !== 'ol'">
                            <template slot="selected-option">
                            <span
                                :class="isAlergiLabel(v.aurd_nama)?'text-warning':''">{{ v.aurd_nama }}</span>
                            </template>
                            <template slot="option" slot-scope="option">
                            <span :class="isAlergiLabel(option.text)?'text-warning':''"
                                v-if="option.preferensi"><b>{{ option.text }}</b></span>
                            <span :class="isAlergiLabel(option.text)?'text-warning':''"
                                v-else>{{ option.text }}</span>
                            </template>
                            <template #list-footer>
                            <li class="list-paging">
                                <div class="d-flex align-items-center justify-content-between">
                                <b-button
                                    @click="changePageObat(v.currentPage,k,'min','resepDokter')"
                                    size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                    <i class="icon-arrow-left22 text-white"></i></b-button>
                                <small>Halaman {{v.currentPage}} dari
                                    {{ceilData(v.totalRows/10)}}</small>
                                <b-button
                                    @click="changePageObat(v.currentPage,k,'plus','resepDokter')"
                                    size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                    <i class="icon-arrow-right22 text-white"></i></b-button>
                                </div>
                            </li>
                            </template>
                        </v-select>
                        <br />
                        </template>

                        <template v-else-if="resepDokter[k]['aurd_jenis'] == 2">
                        <v-select style="flex: 1;" class="med-selection select-paging"
                            placeholder="Pilih Item" v-model="resepDokter[k].aurd_item_id"
                            :options="(paginatedItems||[])" 
                            @open="resetAlkes" @search="filterAlkes($event,k)"
                            label="text"
                            @input="selectJumlahSatuanAlkes($event,k)" :clearable="true"
                            :reduce="v=>v.value">
                            
                            <template slot="selected-option">
                                <span>{{ getConfigDynamic(mAlatKesehatan,resepDokter[k].aurd_item_id)||"-" }}</span>
                            </template>
                            
                            <template slot="option" slot-scope="option">
                                <span>{{option.text}}</span>
                            </template>
                            
                            <template #list-footer>
                                <li class="list-paging">
                                    <div class="d-flex align-items-center justify-content-between">
                                    <b-button
                                        @click="prevPage"
                                        size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                        <i class="icon-arrow-left22 text-white"></i></b-button>
                                    <small>Halaman {{currentPage}} dari
                                        {{totalPages}}</small>
                                    <b-button
                                        @click="nextPage"
                                        size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                        <i class="icon-arrow-right22 text-white"></i></b-button>
                                    </div>
                                </li>
                            </template>

                        </v-select>
                        </template>

                        <template v-else-if="resepDokter[k]['aurd_jenis'] == 3">
                        <v-select style="flex: 1;" class="med-selection select-paging"
                            placeholder="Pilih Item" v-model="resepDokter[k].aurd_item_id"
                            :options="mRacikan" label="text"
                            @input="selectJumlahSatuanRacikan($event,k)" :clearable="true"
                            :reduce="v=>v.value">
                            <template slot="selected-option" slot-scope="option">
                            <span>{{ option.text }}</span>
                            </template>
                            <template slot="option" slot-scope="option">
                            <span>{{ option.text }}</span>
                            </template>
                        </v-select>
                        </template>

                        <template v-else-if="resepDokter[k]['aurd_jenis'] == 4">
                        <v-select style="flex: 1;" class="med-selection select-paging"
                            placeholder="Pilih Item" v-model="resepDokter[k].aurd_item_id"
                            :options="mPaketAlkes" label="text"
                            @input="selectJumlahSatuanPaketAlkes($event,k)" :clearable="true"
                            :reduce="v=>v.value">
                            <template slot="selected-option" slot-scope="option">
                            <span>{{ option.text }}</span>
                            </template>
                            <template slot="option" slot-scope="option">
                            <span>{{ option.text }}</span>
                            </template>
                        </v-select>
                        </template>

                            
                        <template v-else-if="resepDokter[k]['aurd_jenis'] == 5">
                            <v-select style="flex: 1;" class="med-selection select-paging"
                                placeholder="Pilih Item" v-model="resepDokter[k].aurd_item_id"
                                :options="(paginatedItemsInfus||[])" 
                                @open="resetInfus" @search="filterInfus($event,k)"
                                label="text"
                                @input="selectJumlahSatuanInfus($event,k)" :clearable="true"
                                :reduce="v=>v.value">
                                
                                <template slot="selected-option">
                                    <span>{{ getConfigDynamic(mAlatInfus,resepDokter[k].aurd_item_id)||"-" }}</span>
                                </template>
                                
                                <template slot="option" slot-scope="option">
                                    <span>{{option.text}}</span>
                                </template>
                                
                                <template #list-footer>
                                    <li class="list-paging">
                                        <div class="d-flex align-items-center justify-content-between">
                                        <b-button
                                            @click="prevPageInfus"
                                            size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                            <i class="icon-arrow-left22 text-white"></i></b-button>
                                        <small>Halaman {{currentPage}} dari
                                            {{totalPagesInfus}}</small>
                                        <b-button
                                            @click="nextPageInfus"
                                            size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                            <i class="icon-arrow-right22 text-white"></i></b-button>
                                        </div>
                                    </li>
                                </template>

                            </v-select>
                        </template>

                        <a href="javascript:;" v-if="resepDokter[k]['aurd_jenis'] !== 3 && resepDokter[k]['aurd_jenis'] !== 4"
                        @click="resepDokter[k]['aurd_item_id'] = 99999; resepDokter[k]['aurd_nama'] = 'Lainnya'"
                        v-b-tooltip.hover="'Pilih Lainnya'"
                        class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                            class="icon-plus-circle2"></i></a>
                    </div>
                    <VValidate :name="'Nama Item '+(k+1)" message="Nama Item Harus Diisi"
                        v-model="resepDokter[k]['aurd_item_id']" :rules="{required : 1}" />

                    <div v-if="resepDokter[k]['aurd_item_id'] == 99999">
                        <b-form-input type="text" v-model="resepDokter[k]['aurd_item_lainnya']"
                        class="form-control" id="itemCount" />
                        <VValidate :name="'Item Lainnya '+(k+1)"
                        message="Nama Item Lainnya Harus Diisi"
                        v-model="resepDokter[k]['aurd_item_lainnya']" :rules="{required : 1}" />
                    </div>

                    <a v-if="resepDokter[k].aurd_jenis == 3 && resepDokter[k]['aurd_item_id']"
                        href="javascript:;" @click="toRacikan(v)" data-popup="tooltip" title=""
                        data-toggle="modal" data-target="#modalRacikanDetail"
                        class="font-weight-semibold border-bottom"
                        data-original-title="Lihat Detail Resep">Lihat Detail Racikan</a>

                    <a v-if="resepDokter[k].aurd_jenis == 4 && resepDokter[k]['aurd_item_id']"
                        href="javascript:;" @click="toPaketAlkes(v)" data-popup="tooltip" title=""
                        data-toggle="modal" data-target="#modalRacikanDetail"
                        class="font-weight-semibold border-bottom"
                        data-original-title="Lihat Detail Alkes">Lihat Detail Alkes</a>

                    <small class="text-warning"
                        v-if="isAlergiLabel(resepDokter[k].aurd_nama) ">Obat ini termasuk dalam
                        kategori alergi</small>
                    </td>
                    <td>
                    <div class="input-group">
                        <b-form-input type="text" v-model="resepDokter[k]['aurd_jumlah']"
                        :formatter="number" class="form-control" id="itemCount" />
                        <div class="input-group-append">
                        <span class="input-group-text">{{resepDokter[k]['aurd_satuan']}}</span>
                        </div>
                    </div>

                    <VValidate :name="'Jumlah '+(k+1)" message="Jumlah Harus Diisi"
                        v-model="resepDokter[k]['aurd_jumlah']" :rules="{required : 1}" />
                    </td>
                    <td>
                    <span class="d-flex">
                        <v-select style="flex: 1;" class="med-selection"
                        placeholder="Pilih Frekuensi" v-model="resepDokter[k].aurd_frekuensi"
                        :options="mDosis" label="text" :clearable="true" :reduce="v=>v.value">
                        </v-select>
                        <a href="javascript:;" @click="resepDokter[k]['aurd_frekuensi'] = 99999"
                        v-b-tooltip.hover="'Pilih Lainnya'"
                        class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i
                            class="icon-plus-circle2"></i></a>
                    </span>
                    <VValidate :name="'Frekuensi '+(k+1)" message="Frekuensi Harus Diisi"
                        v-model="resepDokter[k]['aurd_frekuensi']" 
                        v-if="resepDokter[k].aurd_jenis != 2"
                        :rules="{required : 1}" />

                    <div v-if="resepDokter[k]['aurd_frekuensi'] == 99999">
                        <b-form-input type="text"
                        v-model="resepDokter[k]['aurd_frekuensi_lainnya']" class="form-control"
                        id="itemCount" />
                        <VValidate :name="'Frekuensi Lainnya '+(k+1)"
                        message="Frekuensi Lainnya Harus Diisi"
                        v-if="resepDokter[k].aurd_jenis != 2"
                        v-model="resepDokter[k]['aurd_frekuensi_lainnya']"
                        :rules="{required : 1}" />
                    </div>
                    </td>
                    <td>
                    <b-textarea v-model="resepDokter[k].aurd_keterangan" name="itemNotes"
                        id="itemNotes" rows="2" placeholder="cth. obat dihabiskan"
                        class="form-control"></b-textarea>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td class="text-center" colspan="99">
                    <a href="javascript:;" @click="openObat()" class="btn alpha-blue"
                        data-toggle="modal" data-target="#modalResep">
                        <i class="icon-plus2 mr-1 align-middle"></i>
                        <span class="align-middle">Tambah Resep</span>
                    </a>
                    </td>
                </tr>
                </tfoot>
            </table>
          </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <div class="text-right">
                <button type="button" class="btn" @click="isParent.openResepPerawat = false" data-dismiss="modal">Batal</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                </div>
            </div>
        </div>
      </b-form>
    </validation-observer>

    <b-modal v-model="openRacikan" :title="'Komposisi Racikan'" size="md" hide-footer>
        <div class="modal-body p-0 table-responsive">
            <p>{{rowRacikan.aurd_jumlah||"-"}} {{rowRacikan.aurd_satuan||"-"}} {{rowRacikan.mrd_nama_racikan||"-"}} terdiri
            dari:</p>
            <table class="table table-striped table-sm table-hover table-bordered">
            <thead>
                <tr>
                <th width="40">#</th>
                <th>Nama Obat</th>
                <th>Qty</th>
                <th>Frekuensi</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(v,k) in rowRacikan.aurd_racikan" :key="k+'rowracik'">
                <td>{{k+1}}</td>
                <td>{{getObat(v.value)||"-"}}</td>
                <td>{{v.jumlah||"-"}} {{v.satuan_jumlah||"-"}}</td>
                <td>{{v.dosis||"-"}} {{v.satuan_dosis||"-"}}</td>
                </tr>
            </tbody>
            </table>
        </div>
        <div class="modal-footer p-0 mt-3">

            <a href="javascript:;" @click="doOpenFormRacikan()" data-toggle="modal" data-target="#updateRacikanFarmasi"
            class="btn btn-link" data-dismiss="modal">
            <div class="icon-pencil7"></div>
            Ubah Komposisi Racikan
            </a>
            <button type="button" @click="openRacikan = false" class="btn btn-success btn-labeled btn-labeled-left"
            data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Selesai</button>
        </div>
    </b-modal>

    <validation-observer ref="VFormRacikan">
    <b-form @submit.prevent="doSubmitRacikan">
        <b-modal v-model="openFormRacikan" :title="'Ubah Komposisi Racikan'" size="lg" hide-footer>
        <div class="modal-body p-0">
            <div class="alert alert-info">
            <span>Saat ini Anda sedang melakukan perubahan komposisi racikan
                <strong>{{rowRacikan.mrd_nama_racikan||"-"}}</strong> milik
                <strong>{{rowRacikan.bu_full_name||"-"}}</strong></span>
            </div>
            <div class="form-group">
            <label for="">Komposisi Racikan <strong class="text-danger">*</strong></label>
            <table class="table table-bordered table-striped table-sm">
                <thead>
                <tr>
                    <th width="48">#</th>
                    <th width="33%">Obat</th>
                    <th width="33%">Dosis</th>
                    <th width="33%">Jumlah</th>
                    <th width="33%">Aksi</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(v,k) in rowRacikan.aurd_racikan" :key="k">
                    <td>{{k + 1}}</td>
                    <td>
                    <v-select placeholder="Pilih Obat" @input="selectObat($event,k)"
                        v-model="rowRacikan.aurd_racikan[k]['value']" :options="getMasterObat(rowRacikan.aurd_racikan,k)"
                        label="text" :clearable="true" :reduce="v=>v.value"></v-select>

                    <VValidate :name="'Obat #'+(k+1)" message="Obat Harus Diisi"
                        v-model="rowRacikan.aurd_racikan[k]['value']" :rules="{required : 1}" />
                    </td>
                    <td>
                    <div class="input-group">
                        <input v-model="rowRacikan.aurd_racikan[k]['dosis']" type="text" class="form-control"
                        placeholder="cth. 120">
                        <div class="input-group-append"><input style="width: 90px;"
                            v-model="rowRacikan.aurd_racikan[k]['satuan_dosis']" type="text" class="form-control"
                            placeholder="cth. Kaplet"></div>
                    </div>
                    </td>
                    <td>
                    <div class="input-group">
                        <b-form-input type="text" v-model="rowRacikan.aurd_racikan[k]['jumlah']"
                        :formatter="isParent.number" class="form-control" placeholder="cth. 5" />
                        <div class="input-group-append"><span
                            class="input-group-text">{{rowRacikan.aurd_racikan[k]['satuan_jumlah']||"-"}}</span></div>
                    </div>

                    <VValidate :name="'Jumlah #'+(k+1)" message="Jumlah Harus Diisi"
                        v-model="rowRacikan.aurd_racikan[k]['jumlah']" :rules="{required: 1}" />
                    </td>
                    <td>
                    <a href="javascript:;" @click="rowRacikan.aurd_racikan.splice(k,1)"
                        class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800"
                        data-popup="tooltip" title="Hapus Obat"><i class="icon-trash"></i></a>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td colspan="9" class="text-center">
                    <a href="javascript:;" @click="addObat" class="btn btn-outline-info">
                        <i class="icon-plus2 align-middle mr-1"></i>
                        <span class="align-middle">Tambah Obat</span>
                    </a>
                    </td>
                </tr>
                </tfoot>
            </table>
            </div>
            <div class="w-100"></div>
            <div class="col-md-4">
            <div>
                <label for="">Jumlah & Satuan<strong class="text-danger">*</strong></label>
                <div class="input-group">
                <b-form-input type="text" :formatter="isParent.number" v-model="rowRacikan.aurd_jumlah"
                    class="form-control" id="cth. Racikan Amlodipin" placeholder="Jumlah Default Racikan" />
                <div class="input-group-append">
                    <v-select placeholder="Pilih Satuan" style="width: 160px;" v-model="rowRacikan.aurd_satuan"
                    :options="isParent.mTypeSatuan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                </div>
                </div>
                <VValidate name="Jumlah" v-model="rowRacikan.aurd_jumlah" :rules="{required : 1}" />
                <VValidate name="Satuan" v-model="rowRacikan.aurd_satuan" :rules="{required : 1}" />
            </div>
            </div>
        </div>
        <div class="modal-footer p-0 mt-3">
            <a href="javascript:;" @click="resetData" data-toggle="modal" data-target="#updateRacikanFarmasi"
            class="btn btn-link" data-dismiss="modal">
            Batal
            </a>
            <button type="submit" @click="doSubmitRacikan" class="btn btn-success btn-labeled btn-labeled-left"
            data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Simpan</button>
        </div>
        </b-modal>
    </b-form>
    </validation-observer>

    <b-modal v-model="openPaketAlkes" :title="'Komposisi Paket Alkes'" size="md" hide-footer>
      <div class="modal-body p-0 table-responsive">
        <p>Paket Alkes : {{rowPaketAlkes.mra_nama_racikan||"-"}} terdiri
          dari:</p>
        <table class="table table-striped table-sm table-hover table-bordered">
          <thead>
            <tr>
              <th width="40">#</th>
              <th>Nama Alkes</th>
              <th>Qty</th>
              <th>Frekuensi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(v,k) in rowPaketAlkes.aurd_paket_alkes" :key="k+'rowracik'">
              <td>{{k+1}}</td>
              <td>
                <span>{{ getConfigDynamic(mAlatKesehatan,v.value)||"-" }}</span>
              </td>
              <td>{{v.jumlah||"-"}} {{v.satuan_jumlah||"-"}}</td>
              <td>{{v.dosis||"-"}} {{v.satuan_dosis||"-"}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer p-0 mt-3">

        <a href="javascript:;" @click="doOpenFormAlkes()" data-toggle="modal" data-target="#updateRacikanFarmasi"
          class="btn btn-link" data-dismiss="modal">
          <div class="icon-pencil7"></div>
          Ubah Komposisi Paket Alkes
        </a>
        <button type="button" @click="openPaketAlkes = false" class="btn btn-success btn-labeled btn-labeled-left"
          data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Selesai</button>
      </div>
    </b-modal>

    <validation-observer ref="VFormPaketAlkes">
      <b-form @submit.prevent="doSubmitPaketAlkes">
        <b-modal v-model="openFormPaketAlkes" :title="'Ubah Komposisi Alat Kesehatan'" size="lg" hide-footer>
          <div class="modal-body p-0">
            <div class="alert alert-info">
              <span>Saat ini Anda sedang melakukan perubahan komposisi alat kesehatan
                <strong>{{rowPaketAlkes.mra_nama_racikan||"-"}}</strong> milik
                <strong>{{rowPaketAlkes.bu_full_name||"-"}}</strong></span>
            </div>
            <div class="form-group">
              <label for="">Komposisi Racikan <strong class="text-danger">*</strong></label>
              <table class="table table-bordered table-striped table-sm">
                <thead>
                  <tr>
                    <th width="48">#</th>
                    <th width="33%">Nama Alkes</th>
                    <th width="33%">Dosis</th>
                    <th width="33%">Jumlah</th>
                    <th width="33%">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v,k) in rowPaketAlkes.aurd_paket_alkes" :key="k">
                    <td>{{k + 1}}</td>
                    <td>
                      <v-select placeholder="Pilih Alkes" @input="selectAlkes($event,k)"
                        v-model="rowPaketAlkes.aurd_paket_alkes[k]['value']" :options="mAlatKesehatan"
                        label="text" :clearable="true" :reduce="v=>v.value"></v-select>

                      <VValidate :name="'Paket Alkes #'+(k+1)" message="Paket Alkes Harus Diisi"
                        v-model="rowPaketAlkes.aurd_paket_alkes[k]['value']" :rules="{required : 1}" />
                    </td>
                    <td>
                      <div class="input-group">
                        <input v-model="rowPaketAlkes.aurd_paket_alkes[k]['dosis']" type="text" class="form-control"
                          placeholder="cth. 120">
                        <div class="input-group-append"><input style="width: 90px;"
                            v-model="rowPaketAlkes.aurd_paket_alkes[k]['satuan_dosis']" type="text" class="form-control"
                            placeholder="cth. Kaplet"></div>
                      </div>
                    </td>
                    <td>
                      <div class="input-group">
                        <b-form-input type="text" v-model="rowPaketAlkes.aurd_paket_alkes[k]['jumlah']"
                          :formatter="$parent.number" class="form-control" placeholder="cth. 5" />
                        <div class="input-group-append"><span
                            class="input-group-text">{{rowPaketAlkes.aurd_paket_alkes[k]['satuan_jumlah']||"-"}}</span></div>
                      </div>

                      <VValidate :name="'Jumlah #'+(k+1)" message="Jumlah Harus Diisi"
                        v-model="rowPaketAlkes.aurd_paket_alkes[k]['jumlah']" :rules="{required: 1}" />
                    </td>
                    <td>
                      <a href="javascript:;" @click="rowPaketAlkes.aurd_paket_alkes.splice(k,1)"
                        class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800"
                        data-popup="tooltip" title="Hapus Alkes"><i class="icon-trash"></i></a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="9" class="text-center">
                      <a href="javascript:;" @click="addAlkes" class="btn btn-outline-info">
                        <i class="icon-plus2 align-middle mr-1"></i>
                        <span class="align-middle">Tambah Alkes</span>
                      </a>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div class="w-100"></div>
          </div>
          <div class="modal-footer p-0 mt-3">
            <a href="javascript:;" @click="resetData" data-toggle="modal" data-target="#updateRacikanFarmasi"
              class="btn btn-link" data-dismiss="modal">
              Batal
            </a>
            <button type="submit" @click="doSubmitAlkes" class="btn btn-success btn-labeled btn-labeled-left"
              data-dismiss="modal"><b><i class="icon-checkmark"></i></b>Simpan</button>
          </div>
        </b-modal>
      </b-form>
    </validation-observer>

  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')
import { stringSimilarity } from "string-similarity-js"
import Config from '@/libs/Config'

export default {
  extends: GlobalVue,
  data(){
    return {
      row: {},
      rowReg: {},

      openFormRacikan: false,
      obatLen: 0,
      currentPage: 1,
      openRacikan: false,
      rowRacikan: {},

      openRiwayatObat: false,
      selectedObat: '',
      searchObat: '',
      
      mTypeSatuan: [],
      mDosis: [],
      mAlatKesehatan: [],
      mAlatInfus: [],
      mRacikan: [],
      resepDokter: [],
      mObatPreferensi: [],
      mObat: [],
    
      pageSize: 10,
      searchQuery: '',
      
      mPaketAlkes: [],
      rowPaketAlkes: {},
      openPaketAlkes: false,
      openFormPaketAlkes: false,
    }
  },
  methods: {
    filterAlkes(e,k){
        this.searchQuery = e
        this.currentPage = 1
    },
    resetAlkes(){
      this.currentPage = 1 
      this.searchQuery = ''
    },
    nextPage() {
        if (this.currentPage < this.totalPages) {
            this.currentPage++
        }
    },
    prevPage() {
        if (this.currentPage > 1) {
            this.currentPage--
        }
    },

    
    filterInfus(e,k){
        this.searchQuery = e
        this.currentPage = 1
    },
    resetInfus(){
      this.currentPage = 1 
      this.searchQuery = ''
    },
    nextPageInfus() {
        if (this.currentPage < this.totalPagesInfus) {
            this.currentPage++
        }
    },
    prevPageInfus() {
        if (this.currentPage > 1) {
            this.currentPage--
        }
    },

    apiGet(){
      this.loadingOverlay = true
      Gen.apiRest(
          "/get/"+'UGDFormPerawat'+'/'+this.isParent.row.ap_id+'/'+this.isParent.idRM+'?regId='+this.isParent.regID, 
      ).then(res=>{
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
        this.loadingOverlay = false
      })

      Gen.apiRest(
        "/get/"+'UGDFormPerawat'+'/'+this.isParent.row.ap_id+'/'+this.isParent.idRM+'?regId='+this.isParent.regID+'&master=1', 
      ).then(res=>{
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
      })
    },

    getObat(val) {
        let index = this.mObat.findIndex(x => x.value == val)
        if (index !== -1) {
            return this.mObat[index]['text']
        } else {
            return null
        }
    },
        
    getMasterObat(data, k) {
        const sldMenu = _.map(_.filter(data || [], (v2, k2) => {
            return k2 != k
        }), v2 => {
            return v2.value
        })
            return _.filter(this.mObat, v2 => {
            return sldMenu.indexOf(v2.value) <= -1
        })
    },

    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if(success){
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
                this.loadingOverlay = true
                
                Gen.apiRest('/do/' + 'UGDFormPerawat', {
                    data: {
                        type: 'save-resep-obat-perawat',
                        regId: this.isParent.regID
                    }
                }, 'POST').then(res => {
                    this.loadingOverlay = false
                    this.$swal({
                        icon: 'success',
                        title: 'Data Berhasil Disimpan'
                    }).then(ok => {
                        if(ok.value){
                            this.isParent.openResepPerawat = false
                        }
                    })
                })
            }
          })
        }
      })
    },
    
    doSubmitRacikan() {
        this.$refs['VFormRacikan'].validate().then(success => {
        if (success) {
            let data = this.rowRacikan
            let idx = this.resepDokter.findIndex(x => x.aurd_id == this.rowRacikan.aurd_id)
            this.resepDokter[idx]['aurd_satuan'] = this.rowRacikan.aurd_satuan
            this.resepDokter[idx]['aurd_jumlah'] = this.rowRacikan.aurd_jumlah
            this.resepDokter[idx]['aurd_racikan'] = this.rowRacikan.aurd_racikan
            this.openFormRacikan = false
        } else {
            setTimeout(() => {
              let inv = []
              let el = document.querySelectorAll('.label_error')
              for (let i = 0; i < (el || []).length; i++) {
                if (el[i].style.display !== 'none') {
                  inv.push(el[i].id)
                }
              }
              if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              })
            }, 500)
        }
        })
    },

    changeJenis(e, k) {
        this.resepDokter[k].aurd_item_id = null
        this.resepDokter[k].aurd_frekuensi = null
        this.resepDokter[k].aurd_frekuensi_lainnya = null
    },
    ceilData(value) {
        return Math.ceil(value)
    },
    selectObat(e, k) {
        let index = this.mObat.findIndex(x => x.value == e)
        if (index !== -1) {
            let data = this.mObat[index]
            this.rowRacikan.aurd_racikan[k]['dosis'] = data.dosis
            this.rowRacikan.aurd_racikan[k]['satuan_dosis'] = data.satuan_dosis
            this.rowRacikan.aurd_racikan[k]['satuan_jumlah'] = data.satuan
        }
    },
    filterObat: _.debounce(function (e, k) {
        if (e) {
            this.resepDokter[k]['search'] = e
            let id = []
            for (let i = 0; i < (this.resepDokter || []).length; i++) {
                if (this.resepDokter[i]['aurd_item_id'] && this.resepDokter[i]['aurd_jenis'] == 1) {
                id.push(this.resepDokter[i]['aurd_item_id'])
                }
            }
            Gen.apiRest('/do/' + 'UGDFormPerawat', {
                data: {
                    type: 'select-obat',
                    search: e,
                    id: id
                }
            }, 'POST').then(res => {
                this.mObatPreferensi = res.data.data
                this.resepDokter[k]['currentPage'] = 1
                this.resepDokter[k]['totalRows'] = res.data.totalRows
            })
        }
    }, 10),


    selectJumlahSatuan(e, k) {
        let data = {
            id: e,
            type: 'get-by-obat'
        }
        Gen.apiRest(
        "/do/" + 'UGDFormPerawat', {
            data: data
        },
        "POST"
        ).then(res => {
            this.resepDokter[k].aurd_satuan = res.data.satuan
            this.resepDokter[k].aurd_nama = res.data.nama
            this.resepDokter[k].aurd_racikan = null

            this.resepDokter[k].aurd_dosis = res.data.dosis
            this.resepDokter[k].aurd_satuan_dosis = res.data.satuan_dosis
            this.resepDokter[k].aurd_item_lainnya = null

            this.isAlergi(res.data.nama, k)
        })
    },

    isAlergi(nama, k) {
        let data = []
        for (let i = 0; i < (this.row.ausd_alergi || []).length; i++) {
            if (this.row.ausd_alergi[i]['jenis'] == 'Obat') {
                data.push(this.row.ausd_alergi[i]['name'])
            }
        }
        let isWarning = 0
        for (let i = 0; i < (data || []).length; i++) {
            let result = stringSimilarity(nama||"", data[i])
            if (result > 0.3) isWarning += 1
        }

        this.resepDokter[k]['aurd_is_alergi'] = isWarning ? "Y" : "N"
    },
    selectJumlahSatuanAlkes(e, k) {
        let data = {
            id: e,
            type: 'get-by-alkes'
        }
        Gen.apiRest(
        "/do/" + 'UGDFormPerawat', {
            data: data
        },
        "POST"
        ).then(res => {
            this.resepDokter[k].aurd_satuan = res.data.satuan
            this.resepDokter[k].aurd_nama = res.data.nama
            this.resepDokter[k].aurd_racikan = null

            this.resepDokter[k].aurd_dosis = null
            this.resepDokter[k].aurd_satuan_dosis = null
            this.resepDokter[k].aurd_frekuensi = null
            this.resepDokter[k].aurd_frekuensi_lainnya = null
            this.resepDokter[k].aurd_item_lainnya = null
        })
    },
    selectJumlahSatuanRacikan(e, k) {
        let data = {
            id: e,
            type: 'get-by-racikan'
        }
        Gen.apiRest(
        "/do/" + 'UGDFormPerawat', {
            data: data
        },
        "POST"
        ).then(res => {
            this.resepDokter[k].aurd_satuan = res.data.satuan
            this.resepDokter[k].aurd_nama = res.data.nama
            this.resepDokter[k].aurd_racikan = res.data.data

            this.resepDokter[k].aurd_dosis = null
            this.resepDokter[k].aurd_satuan_dosis = null
            this.resepDokter[k].aurd_frekuensi = null
            this.resepDokter[k].aurd_frekuensi_lainnya = null
            this.resepDokter[k].aurd_item_lainnya = null
        })
    },
    isAlergiLabel(nama) {
        let data = []
        for (let i = 0; i < (this.row.ausd_alergi || []).length; i++) {
            if (this.row.ausd_alergi[i]['jenis'] == 'Obat') {
                data.push(this.row.ausd_alergi[i]['name'])
            }
        }
        let isWarning = 0
        for (let i = 0; i < (data || []).length; i++) {
            let result = stringSimilarity(nama||"", data[i])
            if (result > 0.3) isWarning += 1
        }
        return isWarning
    },

    countLen() {
        setTimeout(() => {
            this.obatLen = $('.sp-col ul li').length
        }, 100)
    },

    toValidate(val){
        return {...val}
    },

    getConfigDynamic(master, value) {
        let text = ''
        if (value) {
            let index = (master||[]).findIndex(x => x.value == value)
            if (index !== -1) {
                text = master[index]['text']
            }
        }
        return text
    },



    changePageObat(page, k, act, col) {
        if (act == 'min') {
            if (page > 1) {
                page -= 1
                this[col][k]['currentPage'] -= 1
            }
        } else {
            if (page < this.ceilData(this[col][k]['totalRows'] / 10)) {
                page += 1
                this[col][k]['currentPage'] += 1
            }
        }

        let data = {
            type: 'select-paging-obat',
            page: page,
            idDokter: this.rowReg.aur_dokter_id,
            search: this[col][k]['search']
        }
        Gen.apiRest(
        "/do/" + 'UGDFormPerawat', {
            data: data
        },
        "POST"
        ).then(res => {
            this.mObatPreferensi = res.data.data
        })
    },

    openObat(v = null) {
        let data = {
            aurd_aur_id: this.row.aurm_aur_id,
            aurd_aurm_id: this.row.aurm_id,
            aurd_jenis: 2,
            aurd_item_id: null,
            aurd_nama: null,
            aurd_jumlah: null,
            aurd_satuan: null,
            aurd_frekuensi: null,
            aurd_frekuensi_lainnya: null,
            aurd_keterangan: null,
            aurd_is_perawat: "Y",
            id: null
        }
        data.type = 'add-resep-dokter'
        Gen.apiRest(
            "/do/" + 'UGDFormPerawat', {
            data: data
            },
            "POST"
        ).then(res => {
            let resp = res.data
            this.resepDokter.push(resp.row)
        })
    },

    removeObat(v, k) {
      let data = {
          type: 'remove-resep-dokter',
          id: v.aurd_id
      }
      Gen.apiRest(
          "/do/" + 'UGDFormPerawat', {
          data: data
          },
          "POST"
      ).then(res => {
          this.resepDokter.splice(k, 1)
      })
    },

    resetObat() {
        this.loadingOverlay = true
        let data = {
            id: this.row.aurm_id,
            type: 'get-resep-dokter'
        }
        Gen.apiRest(
        "/do/" + 'UGDFormPerawat', {
            data: data
        },
        "POST"
        ).then(res => {
            let resp = res.data
            this.resepDokter = resp.data
            this.loadingOverlay = false
        })
    },


    toRacikan(v) {
        this.loadingOverlay = true
        let data = {
            id: v.aurd_item_id,
            type: 'get-racikan-data'
        }
        Gen.apiRest(
        "/do/" + 'UGDFormPerawat', {
            data: data
        },
        "POST"
        ).then(res => {
            this.loadingOverlay = false
            this.rowRacikan = {
                ...v,
                ...res.data.row
            }
            
            this.mObat = res.data.mObat
            this.openRacikan = true
        })
    },

    doOpenFormRacikan() {
        let data = {
            type: 'get-all-obat'
        }
        Gen.apiRest(
            "/do/" + 'UGDFormPerawat', {
                data: data
            },
            "POST"
        ).then(res => {
            this.mObat = res.data.data
            this.openFormRacikan = true
            this.openRacikan = false
        })
    },

    addObat() {
      this.rowRacikan.aurd_racikan.push({
        value: null,
        dosis: null,
        satuan_dosis: null,
        jumlah: null,
        satuan_jumlah: null,
      })
    },

    autoSaveResepObat: _.debounce(function (data) {
      let dataPost = {
        type: 'auto-save-resep-obat',
        data: data
      }
      Gen.apiRest(
          "/do/" + 'UGDFormPerawat', {
          data: dataPost
          },
          "POST"
      )
    }, 1000),

    
    // POLI ADJSTMENT //
    selectJumlahSatuanPaketAlkes(e, k) {
        let data = {
            id: e,
            type: 'get-by-paket-alkes'
        }
        Gen.apiRest(
        "/do/" + 'UGDFormPerawat', {
            data: data
        },
        "POST"
        ).then(res => {
            this.resepDokter[k].aurd_satuan = res.data.satuan
            this.resepDokter[k].aurd_nama = res.data.nama
            this.resepDokter[k].aurd_paket_alkes = res.data.data

            this.resepDokter[k].aurd_dosis = null
            this.resepDokter[k].aurd_satuan_dosis = null
            this.resepDokter[k].aurd_frekuensi = null
            this.resepDokter[k].aurd_frekuensi_lainnya = null
            this.resepDokter[k].aurd_item_lainnya = null
        })
    },
    toPaketAlkes(v) {
        this.loadingOverlay = true
        let data = {
            id: v.aurd_item_id,
            type: 'get-paket-alkes-data'
        }
        Gen.apiRest(
        "/do/" + 'UGDFormPerawat', {
            data: data
        },
        "POST"
        ).then(res => {
            this.loadingOverlay = false
            this.rowPaketAlkes = {
                ...v,
                ...res.data.row
            }
            this.openPaketAlkes = true
        })
    },
    
    doOpenFormAlkes(){
        this.openFormPaketAlkes = true
        this.openPaketAlkes = false
    },
    
    selectAlkes(e, k) {
        let index = this.mAlatKesehatan.findIndex(x => x.value == e)
        if (index !== -1) {
            let data = this.mAlatKesehatan[index]
            console.log(data)
            this.rowPaketAlkes.aurd_paket_alkes[k]['dosis'] = data.dosis
            this.rowPaketAlkes.aurd_paket_alkes[k]['satuan_dosis'] = data.satuan_dosis
            this.rowPaketAlkes.aurd_paket_alkes[k]['satuan_jumlah'] = data.satuan
        }
    },
    
    doSubmitAlkes() {
        this.$refs['VFormPaketAlkes'].validate().then(success => {
            if (success) {
                let data = this.rowPaketAlkes
                let idx = this.resepDokter.findIndex(x => x.aurd_id == this.rowPaketAlkes.aurd_id)
                this.resepDokter[idx]['aurd_paket_alkes'] = this.rowPaketAlkes.aurd_paket_alkes
                this.openFormPaketAlkes = false
            } else {
                setTimeout(() => {
                let inv = []
                let el = document.querySelectorAll('.label_error')
                for (let i = 0; i < (el || []).length; i++) {
                    if (el[i].style.display !== 'none') {
                    inv.push(el[i].id)
                    }
                }
                if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                })
                }, 500)
            }
        })
    },
    addAlkes(){
       this.rowPaketAlkes.aurd_paket_alkes.push({
        value: null,
        dosis: null,
        satuan_dosis: null,
        jumlah: null,
        satuan_jumlah: null,
      })
    },
    
    resetData() {
        this.openFormRacikan = false
        this.openFormPaketAlkes = false
        this.apiGet()
    },

    
    selectJumlahSatuanInfus(e, k) {
        let data = {
            id: e,
            type: 'get-by-infus'
        }
        Gen.apiRest(
        "/do/" + 'UGDFormPerawat', {
            data: data
        },
        "POST"
        ).then(res => {
            this.resepDokter[k].aurd_satuan = res.data.satuan
            this.resepDokter[k].aurd_nama = res.data.nama
            this.resepDokter[k].aurd_racikan = null

            this.resepDokter[k].aurd_dosis = null
            this.resepDokter[k].aurd_satuan_dosis = null
            this.resepDokter[k].aurd_frekuensi = null
            this.resepDokter[k].aurd_frekuensi_lainnya = null
            this.resepDokter[k].aurd_item_lainnya = null
        })
    },

    // POLI ADJSTMENT //
  },
  computed: {
    isParent(){
      return this.$parent.$parent.$parent
    },
    filteredItems() {
        return this.mAlatKesehatan.filter(item =>
            item.text.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
    },
    paginatedItems() {
        const startIndex = (this.currentPage - 1) * this.pageSize
        const endIndex = startIndex + this.pageSize
        return this.filteredItems.slice(startIndex, endIndex)
    },
    totalPages() {
        return Math.ceil(this.filteredItems.length / this.pageSize) || 1
    },

    filteredItemsInfus() {
        return this.mAlatInfus.filter(item =>
            item.text.toLowerCase().includes(this.searchQuery.toLowerCase())
        )
    },
    paginatedItemsInfus() {
        const startIndex = (this.currentPage - 1) * this.pageSize
        const endIndex = startIndex + this.pageSize
        return this.filteredItemsInfus.slice(startIndex, endIndex)
    },
    totalPagesInfus() {
        return Math.ceil(this.filteredItemsInfus.length / this.pageSize) || 1
    },

    Config(){ return Config },
  },
  mounted(){
    this.apiGet()
  },
  watch:{
    resepDokter: {
      handler(v) {
        this.autoSaveResepObat(v)
      },
      deep: true
    },
  }
}

</script>